.slide h1 {
    transition: all 0.3s ease;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
}

.slide button {
    transition: all 0.3s ease;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
}

.slide p {
    transition: all 0.3s ease;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
}

.slide section * {
    transition: all 0.3s ease;
}

.slide section img {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
    opacity: 0;
}

.slide section span {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
}

.slide section span strong {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition-delay: .9s;
    transition-delay: .9s;
    opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
    -webkit-transition-delay: 1.1s;
    transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
    -webkit-transition-delay: 1.3s;
    transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
    -webkit-transition-delay: 1.3s;
    transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
    -webkit-transition-delay: 1.4s;
    transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
    -webkit-transition-delay: 1.5s;
    transition-delay: 1.5s;
}

.slide.animateOut h1 {
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
}

.slide.animateOut p {
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
}

.slide.animateOut section span {
    -webkit-transition-delay: .1s;
    transition-delay: .1s;
}

.slide.animateOut section span strong {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

@media (max-width: 1515px) {
    header section {
        max-width: 100%;
    }
}

header section .logo {
    transform: translateX(-87px);
}

header section a {
    float: right;
    padding: 3px 0;
}

* {
    outline: none;
}


.wrapper {
    max-width: 1335px;
    box-sizing: border-box;
    padding: 0 20px;
    margin: auto;
}

.wrapper a {
    display: inline-block;
    margin: 5px;
}

.wrapper a:first-child {
    margin-left: 0;
}

.slider-wrapper {
    position: relative;
    height: 70vh;
    overflow: hidden;
}

.slide {
    height: 70vh;
    background-size: cover !important;
} 

.previousButton,
.nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.previousButton polygon,
.nextButton polygon {
    fill: rgb(42, 117, 160);
}

.previousButton {
    left: 0; 
}

.nextButton {
    right: 0;
}

.slider-content {
    text-align: center;
}

.slider-content .inner {
    padding: 0 70px;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.slider-content .inner button {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.slider-content .inner h1 {
    font-weight: 900;
    margin: 0 auto;
    max-width: 840px;
    color: #FFFFFF;
    text-shadow: 1px 2px rgb(107, 106, 106);
    font-size: 40px;
    line-height: 1;
}

.slider-content .inner p {
    color: #FFFFFF;
    font-size: 18px;
    line-height: 1.5;
    text-shadow: 1px 1px rgb(143, 139, 139);
    margin: 20px auto 30px;
    max-width: 640px;
}

.slider-content section {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.slider-content section span {
    color: #FFFFFF;
}

.slider-content section span {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    display: inline-block;
    text-align: left;
    line-height: 1.4;
    vertical-align: middle;
    margin-left: 10px;
}

.slider-content section img {
    width: 40px;
    height: 40px;
    border: solid 2px rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    vertical-align: middle;
}

.slider-content section span strong {
    color: #FFFFFF;
    font-size: 12px;
    display: block;
}

@media (max-height: 500px) {

    .slider-wrapper,
    .slide {
        height: calc(100vh - 75px);
    }
}

@media (max-width: 640px) {

    .slider-wrapper,
    .slide {
        height: calc(80vh - 75px);
    }
}

@media (max-height: 600px) {
    .slider-content .inner h1 {
        font-size: 20px;
    }
}

@media (max-width: 640px) {
    .slider-content .inner h1 {
        font-size: 20px;
    }
    .slider-content .inner p {
        font-size: 16px;
    }
}