body,
html {
    font-family: 'Open Sans', sans-serif;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    color: #777;
    font-weight: 400;
    width: 100% !important;
    height: 100% !important;
    scroll-behavior: smooth;
}

h2,
h3,
h4 {
    font-family: 'Raleway', sans-serif;
}

h2 {
    text-transform: uppercase;
    margin: 0 0 20px 0;
    font-weight: 800;
    font-size: 36px;
    color: #333;
}

h3 {
    font-size: 20px;
    font-weight: 600;
    color: #333;
}

h4 {
    font-size: 18px;
    color: #333;
    font-weight: 600;
}

h5 {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 20px;
}

p {
    font-size: 15px;
}

p.intro {
    margin: 12px 0 0;
    line-height: 24px;
}

ul,
ol {
    list-style: none;
}

ul,
ol {
    padding: 0;
    webkit-padding: 0;
    moz-padding: 0;
}


hr {
    height: 4px;
    width: 250px;
    background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
    border: 0;
}

.general-section {
    padding: 100px 0 100px;
}

.btnIcon {
    margin-left: 15px;
    font-size: 27px;
    position: relative;
    top: 3px;
}

/* Navigation */

.fixed-top {
    top: 30px !important;
}

#aboveNavPhone {
    text-align: center;
    background-color: #4a71ab;
    color: #f7eded;
    text-shadow: 3px 1px 6px black;
    font-size: 15px;
    padding: 5px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

#logo {
    display: table;
    width: 150px;
    height: 120px;
    position: absolute;
    top: 20px;
    background: url(./resources/images/logo.jpg) center center no-repeat;
    background-color: #e5e5e5;
    background-size: cover;
}

@media (max-width: 768px) {
    #logo {
        width: 50px;
        height: 40px;
    }
}

#navigationBar {
    padding: 15px;
    transition: all 0.8s;
}

#navigationBar a.navbar-brand {
    color: white;
    font-family: 'Raleway', sans-serif;
    font-size: 1px;
    font-weight: 700;
    text-transform: uppercase;
}

#navbarMenu {
    justify-content: center;
}

#navbarMenu .navbar-nav>a {
    color: #c1c1c1;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    padding: 8px 2px;
    border-radius: 0;
    margin: 9px 20px 0 20px;
    border-bottom: 3px solid transparent;
    transition: 0.2s ease-in all;
}


/* #navbarMenu .navbar-nav>a:hover {
    border-bottom: 3px solid #185da6;
} */

.navbar-nav div {
    margin-left: 20px;
}

.navbar-nav div i.fab,
#contact i.fab {
    font-size: 20px;
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 50%;
    transition: all 0.3s;
    text-align: center;
    cursor: pointer;
    padding: 9px 0;
    margin-top: 7px;
    cursor: pointer;
}

.navbar-nav div i.fab:hover,
#contact i.fab:hover {
    color: #608dfd;
    background: #fff;
}
.navbar-nav div i.fab.fa-instagram:hover,
#contact i.fab.fa-instagram:hover {
    color: #f227c2;
    background: #fff;
}

.pointer {
    cursor: pointer !important;
}

.navOffset {
    margin-top: 100px;
}

.section-title {
    margin-bottom: 70px;
}

.section-title h2 {
    position: relative;
    margin-top: 40px;
    margin-bottom: 15px;
}

.section-title p {
    font-size: 18px;
}

.btn-custom {
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    color: #fff;
    background-color: #5ca9fb;
    background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
    padding: 10px 20px;
    letter-spacing: 1px;
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    border-radius: 25px;
    transition: all 0.5s linear;
    border: 0;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
    color: #fff;
    background-image: none;
    background-color: #6372ff;
}

.btn:active,
.btn.active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
    outline: none;
    outline-offset: none;
}

/* Header Section */
.intro {
    display: table;
    width: 100%;
    height: 100vh;
    padding: 0;
    background: url(./resources/images/header-image.jpg) center center no-repeat;
    background-color: #e5e5e5;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.intro .overlay {
    background: rgba(0, 0, 0, 0.2);
    height: 100vh;
}

.slider-content .inner {
    background-color: #222222b8;
    top: 26% !important;
    padding: 40px 70px !important;
}

.headerWidth {
    width: 95% !important;
    max-width: 2000px !important;
}

.intro h1 {
    font-family: 'Raleway', sans-serif;
    color: #fff;
    font-size: 30px !important;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 10px;
}

.intro h1 span {
    font-weight: 800;
    color: #5ca9fb;
}

.intro p {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
    margin: 0 auto;
    margin-bottom: 60px;
}

header .intro-text {
    padding-top: 150px;
    padding-bottom: 200px;
    text-align: center;
}

.fbBanner {
    /* background: url(./resources/images/dmMont-facebook.png) center center no-repeat; */
    height: 400px;
    background-size: contain;
    margin: 50px;
}

.fbBanner .fbBanner-link {
    height: 250px;
    width: 75%;
    display: block;
    position: relative;
    top: 20%;
    margin: 0 auto;
}

.instaBanner {
    background: linear-gradient(to right, #2f2f37 0%, #254362 100%) !important;
    padding: 50px;
}
.instaBanner-heading {
    color: rgb(223, 223, 223);
    text-align: center;
    font-weight: bold;
    font-size: 25px;
}
.instaBanner-heading span a {
    color: #d10082;
}
.instaBanner-heading span a:hover {
    cursor: pointer;
}
.instabanner-pictures {
    padding: 20px;
}
.instaPic {
    padding: 10px;
}

/* products Section */
#products {
    padding: 100px 0;
    background: #f6f6f6;
}

#products i.fa {
    font-size: 38px;
    margin-bottom: 20px;
    transition: all 0.5s;
    color: #fff;
    width: 100px;
    height: 100px;
    padding: 30px 0;
    border-radius: 50%;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, .05);
}

#products label.productCategory {
    font-size: 19px;
    padding: 5px;
    border: 2px solid #cdcdcd;
    position: absolute;
    z-index: 99;
    width: 260px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #e5e5e5;
    cursor: pointer;
    text-transform: uppercase;
}

#products img {
    width: 350px;
    margin: 30px 0;
    height: 200px;
    object-fit: cover;
    filter: brightness(60%);
}

/* About Section */
#about {
    /* background: linear-gradient(to right, #141943 0%, #185da6 100%); */
    background: linear-gradient(to right, #2f2f37 0%, #254362 100%) !important;
    color: rgba(255, 255, 255, .75);
}
#about {
    padding: 100px 0;
}

#about h3 {
    font-size: 22px;
    margin: 0 0 20px 0;
}

#about h2 {
    position: relative;
    margin-bottom: 15px;
    text-align: center;
}

#about .about-text li {
    margin-bottom: 6px;
    margin-left: 6px;
    list-style: none;
    padding: 0;
}

#about .about-text li:before {
    content: '\f00c';
    font-family: 'FontAwesome';
    color: #5ca9fb;
    font-size: 11px;
    font-weight: 300;
    padding-right: 8px;
}

#about img {
    width: 520px;
    margin-top: 10px;
    background: #fff;
    border-right: 0;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}

#about p {
    line-height: 24px;
    margin: 30px 0;
}

#about .counterContainer {
    width: 150px;
    height: 150px;
    background-color: #26496d;
    border-radius: 50%;
    margin: 0 auto;
    text-align: center;
    position: relative;
}

#about .counterContainer label {
    font-size: 35px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#about .counterTitle {
    position: relative;
    top: 20px;
    color: rgb(61, 57, 57);
    font-size: 18px;
    font-weight: bold;
}

#about .counterMargin {
    margin-bottom: 30px;
}


/* Services Section */
#services-banner {
    text-align: center;
    background-color: #920044;
    padding: 10px;
    color: #f7eded;
    font-size: 20px;
    text-shadow: 3px 1px 6px black;
}

#services {
    padding: 100px 0;
    background: linear-gradient(to right, #2f2f37 0%, #254362 100%) !important;
    color: #fff;
}

#services .service-desc {
    margin: 10px 10px 20px;
}

#services h2 {
    color: #fff;
}

#services i.fa,
#services i.fas,
#services i.fab {
    font-size: 42px;
    width: 120px;
    height: 120px;
    padding: 40px 0;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    border-radius: 50%;
    color: #fff;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, .05);
}

#services div.counter {
    font-size: 42px;
    width: 120px;
    height: 120px;
    padding: 40px 0;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    border-radius: 50%;
    color: #fff;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, .05);
}

#services h3 {
    margin-top: 20px;
    font-weight: 500;
    padding: 5px 0;
    color: #fff;
}

#services p {
    color: rgba(255, 255, 255, .75);
}

#services .service-desc {
    margin-bottom: 40px;
}

/* Portfolio Section */
#portfolio {
    padding: 100px 0;
}

.portfolio-item {
    margin: 1px -15px 0 -14px;
    padding: 0;
}

.portfolio-item .hover-bg {
    overflow: hidden;
    position: relative;
    margin: 0;
}

.hover-bg .hover-text {
    position: absolute;
    text-align: center;
    margin: 0 auto;
    color: #fff;
    background: linear-gradient(to right, #2f2f37 0%, #254362 100%) !important;
    padding: 30% 0 0 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all 0.5s;
}

.hover-bg .hover-text>h4 {
    opacity: 0;
    color: #fff;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    transition: all 0.3s;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 500;
    text-transform: uppercase;
}

.hover-bg:hover .hover-text>h4 {
    opacity: 1;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.hover-bg:hover .hover-text {
    opacity: 1;
}

/* Testimonials Section */
#testimonials {
    padding: 100px 0;
    background: linear-gradient(to right, #2f2f37 0%, #254362 100%) !important;
    color: #fff;
}

#testimonials h2 {
    color: white;
}

#testimonials i {
    color: #e6e6e6;
    font-size: 32px;
    margin-bottom: 20px;
}
#testimonials i.fa-star {
    font-size: 15px;
    margin-left: 5px;
}

.testimonial {
    position: relative;
    padding: 20px;
}

.testimonial-image {
    float: left;
    margin-right: 15px;
}

.testimonial-image,
.testimonial-image img {
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
}

.testimonial-content {
    position: relative;
    overflow: hidden;
}

.testimonial-content p {
    margin-bottom: 0;
    font-size: 14px;
    font-style: italic;
}

.testimonial-meta {
    margin-top: 10px;
    font-size: 15px;
    font-weight: 600;
    color: #c1e6ec;
}

/* Team Section */
#team {
    padding: 100px 0;
}

#team h4 {
    margin: 5px 0;
}

#team .team-img {
    width: 240px;
}

#team .thumbnail {
    background: transparent;
    border: 0;
}

#team .thumbnail .caption {
    padding: 10px 0 0 0;
    color: #888;
}

/* Gallery Section */
#gallery img {
    width: 200px;
    margin: 10px;
}

/* Inspiration Section */
#inspiration img {
    width: 200px;
    margin: 10px;
}

.blueBackground {
    background: linear-gradient(to right, #2f2f37 0%, #254362 100%) !important;
    color: white;
}

.blueBackground h2 {
    color: white;
}

/* Inspiration Section */
#blog img {
    width: 300px;
    margin: 0 20px 20px;
    float: left;
}
#blog .blogPost {
    padding: 10px;
    box-shadow: 1px 2px 4px 4px #e2dfdf;
}

@media (max-width: 768px) {
    #blog img {
        width: 150px;
    }
}

#blog .hidden {
    display: none;
}

#blog .expand {
    padding: 10px 20px;
    background-color: #313a41;
    border-radius: 15px;
    color: #f8f8f8;
    cursor: pointer;
}

#blog .expand:hover {
    background-color: #5a758a;
}

.margin-bottom {
    margin-bottom: 50px;
}

/* Contact Section */
#contact {
    padding: 100px 0 60px 0;
    /* background: linear-gradient(to right, #141943 0%, #185da6 100%); */
    background: linear-gradient(to right, #2f2f37 0%, #254362 100%) !important;
    color: rgba(255, 255, 255, .75);
}

#contact .section-title {
    margin-bottom: 40px;
}

#contact .section-title p {
    font-size: 16px;
}

#contact .about-text {
    padding: 15px;
}

#contact h2 {
    color: #fff;
    margin-top: 10px;
    margin-bottom: 15px;
}

#contact h3 {
    color: #fff;
    padding-bottom: 20px;
    font-weight: 400;
}

#contact form {
    padding-top: 20px;
}

#contact .text-danger {
    color: #cc0033;
    text-align: left;
}

#contact .btn-custom {
    margin: 30px 0;
    background: transparent;
    border: 2px solid #fff;
}

#contact .btn-custom:hover {
    color: #1f386e;
    background: #fff;
}

label {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    float: left;
}

#contact .form-control {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #444;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ddd;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

#contact .form-control:focus {
    border-color: #999;
    outline: 0;
    -webkit-box-shadow: transparent;
    box-shadow: transparent;
}

.form-control::-webkit-input-placeholder {
    color: #777;
}

.form-control:-moz-placeholder {
    color: #777;
}

.form-control::-moz-placeholder {
    color: #777;
}

.form-control:-ms-input-placeholder {
    color: #777;
}

#contact .contact-item {
    margin: 20px 0;
}

#contact .contact-item span {
    color: rgba(255, 255, 255, 1);
    margin-bottom: 10px;
    display: block;
}

#contact .contact-item i.fa {
    margin-right: 10px;
}

#contact .social {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    padding-top: 20px;
    margin-top: 20px;
    text-align: center;
}

#contact .social ul li {
    display: inline-block;
    margin: 0 20px;
}

#contact .social i.fa {
    font-size: 22px;
    width: 48px;
    height: 48px;
    padding: 12px 0;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 50%;
    transition: all 0.3s;
}

#contact .social i.fa:hover {
    color: #608dfd;
    background: #fff;
}

#contact .followUs {
    font-weight: bold;
    font-size: 20px;
    color: white;
}
/* Footer Section*/
#footer {
    background: #f6f6f6;
    padding: 30px 0;
}

#footer p {
    color: #888;
    font-size: 14px;
}

#footer a {
    color: #608dfd;
}

#footer a:hover {
    border-bottom: 2px solid #608dfd;
}

#administration {
    margin-top: 150px;
}

#administration #passwordBox {
    top: 106px;
    position: relative;
    text-align: center;
}
#administration #passwordBox input[type="button"] {
    background-color: #319a8b;
    color: white;
    padding: 5px 30px;
    margin: 20px 0px;
    border: none;
    border-radius: 5px;
}

#administration .keyTile {
    display: inline-block;
    background-color: #4694b6;
    color: white;
    padding: 25px 50px;
    margin: 10px 10px 10px 0;
    cursor: pointer;
    border-radius: 8px;
}

#administration .keyTile.selected {
    background-color: #1f5064;
}

#administration .tiles-section {
    margin-bottom: 30px;
}

#propertyContainer {
    margin-bottom: 30px;
}

#divContainer {
    margin-bottom: 30px;
}

#bsTable .large {
    min-width: 400px;
}

#bsTable .medium {
    min-width: 300px;
}

#bsTable .small {
    min-width: 200px;
}

#bsTable .mega-small {
    min-width: 70px;
}

#propertyContainer label {
    font-weight: bold;
    color: #3e869e;
    font-size: 18px;
    margin-right: 10px;
}

#propertyContainer input {
    width: 600px;
}

.actionButton {
    background-color: #319a8b;
    color: white;
    padding: 5px 30px;
    margin: 20px 0px;
    border: none;
    border-radius: 5px;
}

.actionButton:hover {
    background-color: #207569;
}

.saveButton {
    background-color: #268a3b;
    color: white;
    padding: 5px 30px;
    margin: 20px 0px;
    border: none;
    border-radius: 5px;
    float: right;
}

.saveButton:hover {
    background-color: #0a5f2b;
}

.saveButton:disabled {
    background-color: #a8b1ab;
}

.linkToFullView {
    position: relative;
    top: 50px;
    text-transform: uppercase;
    color: #fff;
    background-color: #5ca9fb;
    background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
    padding: 10px 20px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 20px;
    transition: all 0.2s linear;
    text-decoration: none;
}

.linkToFullView:hover {
    background: linear-gradient(to right, #7691ad 0%, #292958 100%);
    color: white;
    text-decoration: none;
    cursor: pointer;
}

@media (min-width: 1000px) {
    #googleMap {
        width: 700px;
    }
}

@media (max-width: 700px) {
    header .intro-text {
        padding-top: 100px !important;
    }
    header .slider-content .inner h1 {
        font-size: 12px !important;
    }
    header .slider-content .inner {
        padding: 20px 10px !important
    }
    #services-banner {
        font-size: 13px;
    }
    .instaBanner-heading {
        font-size: 16px;
     }
    .linkToFullView {
        padding: 8px 12px;
        font-size: 10px;
    }
    .linkToFullView i {
        font-size: 15px;
        top: 1px;
        margin-left: 7px;
    }
    .service-desc h3 {
        font-size: 16px;
    }
    h2 {
        font-size: 30px;
    }

    .fbBanner .fbBanner-link {
        height: 400px !important;
        top: 0% !important;
    }
}


@media (max-width: 600px) {
    .fixed-top {
        top: 50px!important;
    }
}

